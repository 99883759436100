import styles from './style.module.css';
import { IInternalText } from './GoannaCardHelper';

interface IInnerRow {
  data: IInternalText
  numberColor?: string
}

export function InnerRow({ data, numberColor }: IInnerRow) {
  return (
    <tr className={styles.ContentText}>
      <th className={styles.ContentTextNumber} style={{ color: numberColor }}>
        {data.TextNumber ? data.TextNumber.toString() : '0'}
      </th>
      <td className={styles.ContentTextAndDate}>
        <div className={styles.ContentTextData}>
          {data.TextData}
        </div>
        <div className={styles.ContentTextDate}>
          {data.dateString}
        </div>
      </td>
    </tr>
  )
}

export default InnerRow;