import * as React from 'react';

import { UseQueryResult } from '@tanstack/react-query';

import useSoilProbesData, { soilProbesDataType } from '../../hooks/ApiHooks/useSoilProbesData';
import useLocationDevicesData, { locationDevicesDataType } from '../../hooks/ApiHooks/useLocationDevicesData';
import useBOMForecastData from '../../hooks/ApiHooks/useBOMForecastData';
import { DateTime } from 'luxon';
import useSoilTotalDatawithTemp, { soilTotalDatawithTemp } from '../../hooks/ApiHooks/useSoilTotalDatawithTemp';
import useRainEventDataSummaryData, { rainEventDataSummaryDataType } from '../../hooks/ApiHooks/useRainEventDataSummaryData';
import { getDaysBefore, getDaysAfter, CURRENT_TIME_AEST, aestToLocationLocalTimeConverter, AEST_Zone } from '../../utils/DateConvertor';
import { DateRange } from '@mui/x-date-pickers-pro';
import { ApolloQueryResult, FetchPolicy, gql, QueryResult, useLazyQuery } from '@apollo/client';
import useGetRootZoneDailyUsageFromCached from '../../hooks/ApiHooks/useGetRootZoneDailyUsageFromCached';
import useDevicesData, { DevicesDataType } from '../../hooks/ApiHooks/useDevicesData';
import useGetSatelliteFieldDataSummaryForDateRange from '../../hooks/ApiHooks/useGetSatelliteFieldDataSummaryForeDateRange';
import useLocationCropsByLocationIDData, { LocationCropsByLocationIDAPIReturnType } from '../../hooks/ApiHooks/useLocationCropsByLocationIDData';
import useLocationCropReportSummaryByLocationIDData, { LocationCropReportSummaryByLocationIDType } from '../../hooks/ApiHooks/useLocationCropReportSummaryByLocationIDData';
import { useSelector } from 'react-redux';
import useCanopyFields, { CanopyFieldsDataType } from '../../hooks/ApiHooks/useCanopyFields';
import { client, secondaryClient } from '../../api/apollo';
import { useAppDispatch, useAppSelector } from '../../stores/hooks';
import { useSnackbar } from 'notistack';
import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { LocationEditAPIReturnType, LocationsEditDataType, useLocationEdit } from '../../hooks/ApiHooks/useLocationsEdit';

export interface FieldDetailDataType {
  locationID: string,
  dateRange: DateRange<DateTime>,
  setDateRange: Function,
  soilProbesData: UseQueryResult<soilProbesDataType, unknown>,
  BOMForecastData: UseQueryResult<any, unknown>,
  soilTotalDatawithTemp: UseQueryResult<soilTotalDatawithTemp, unknown>,
  rainEventDataSummaryData: UseQueryResult<rainEventDataSummaryDataType, unknown>,
  canopyFieldsData: CanopyFieldsDataType | undefined,
  canopyStressForecast: QueryResult<any, {
    unit: string;
    location_id: any;
  }>
  canopyStress: any
  locationDevicesData: UseQueryResult<locationDevicesDataType, unknown>,
  satLocationDevicesData: UseQueryResult<locationDevicesDataType, unknown>,
  LocationCropsByLocationIDData: UseQueryResult<LocationCropsByLocationIDAPIReturnType, unknown>,
  LocationsEdit: UseQueryResult<LocationEditAPIReturnType, unknown>,
  LocationCropReportSummaryByLocationIDData: UseQueryResult<LocationCropReportSummaryByLocationIDType, unknown>,
  rootzoneData: UseQueryResult<any, unknown>,
  deviceData: UseQueryResult<DevicesDataType, unknown>,
  satelliteFieldDataSummary: UseQueryResult<any, unknown>,
  startDate: DateTime | null,
  refetchCanopyStress: () => void;
  canopyStressLoading: boolean;
  loading: boolean;
  dateRangeReady: boolean;
  interval: number;
  setInterval: any;
  userAccounts: any;
}

const FieldDetailContext = React.createContext({} as FieldDetailDataType);

const unit: string[] = ['celsius', 'fahrenheit']

export interface IFieldDetailProviderProps {
  locationID: string,
  children: React.ReactNode,
}



function getCanopyStresses(locationID: any, soilProbesData: soilProbesDataType | undefined, canopyFieldsData: CanopyFieldsDataType | undefined, dateRange: DateRange<DateTime>, numericSystem: string, startDate?: string | DateTime | null, interval?: number, fetchPolicy?: 'no-cache' | undefined): Promise<ApolloQueryResult<any>> {

  let endDateCanopy;

  if (soilProbesData?.data?.value && soilProbesData.data.value.length > 0) {
    endDateCanopy = soilProbesData.data.value[0]?.EndDateCanopy;
  } else if (canopyFieldsData?.data?.value && canopyFieldsData.data.value.length > 0) {
    endDateCanopy = canopyFieldsData.data.value[0]?.EndDateCanopy;
  } else {
    endDateCanopy = undefined;
  }

  const fromDate = dateRange[0] ?? startDate;

  const isV3 = interval !== undefined && interval != 0;

  const CANOPY_STRESS = gql`
  query ($unit: UnitEnum!, $location_id: ID!) {
        canopy_stresses(
          where: {
            location_id: $location_id
            start_date: "${(fromDate as DateTime).toFormat("yyyy-MM-dd'T00:00:00'")}"
            end_date: "${(dateRange[1] as DateTime).toFormat("yyyy-MM-dd'T23:00:09'")}"
            ${endDateCanopy ? ('end_date_canopy: "' + endDateCanopy + '"') : ""}
          }
          legacy: true${isV3 ? `, version: "3",  sampling_rate: ${interval}` : ''}
        ) {
          threshold
          periods {
            type
            start_date
            end_date
            start_deficit_hours
            end_deficit_hours
          }
          data {
            date_time
            temp(digits: 2, unit: $unit)
            air_temp(digits: 2, unit: $unit)
            deficit_hours
            ambient_temp(digits: 2, unit: $unit)
            rel_humid
            stress_time
            solar_rad
            stress_time_above_threshold
            stress_time_above_5_hours
            acc_stress_time(unit: percentage, digits: 2)
            acc_stress_time_above_threshold(digits: 2, unit: percentage)
          }
        }
      }
    `;

  return client.query({
    query: CANOPY_STRESS,
    variables: {
      'unit': numericSystem === 'M' ? unit[0] : unit[1],
      location_id: locationID,
    },
    fetchPolicy: fetchPolicy
  });
}

export const NOW = DateTime.now();

function FieldDetailProvider(props: IFieldDetailProviderProps) {
  
  const [interval, setInterval] = React.useState(60);
  const { enqueueSnackbar } = useSnackbar();
  const { numericSystem }: { numericSystem: string } = useSelector((state: any) => state.user);
  const _dateRange = useAppSelector(state => state.user.dateRange);
  const debug = useAppSelector(state => state.user.debug);
  const [startDate, setStartDate] = React.useState<DateTime>(getDaysBefore(NOW, Number(_dateRange)));
  const [dateRange, setDateRange] = React.useState<DateRange<DateTime>>([getDaysBefore(NOW, Number(_dateRange) === -1 ? 7 : Number(_dateRange)), getDaysAfter(NOW, 16)]);
  const [dateRangeReady, setDateRangeReady] = React.useState(false);
  const LocationsEdit = useLocationEdit(props.locationID);
  const { t } = useTranslation("FieldDetail")

  const soilProbesData = useSoilProbesData(props.locationID, (spd_data) => {
    const [_startDate, _dateRange] = setStartDateDateRange(spd_data, locationDevicesData.data ?? {} as locationDevicesDataType);
    refetchBomForecastDateRange(
      CURRENT_TIME_AEST.plus({ hour: spd_data.data?.value[0].GMTDifference }).toFormat("yyyyMMdd'T'HHmmss"),
      CURRENT_TIME_AEST
        .plus({ hour: spd_data.data?.value[0].GMTDifference })
        .plus({ days: 10 })
        .toFormat("yyyyMMdd'T'HHmmss")
    );
    refetchRainEventDataSummaryData(
      (dateRange[0] as DateTime).toFormat("yyyyMMdd'T000000'"),
      CURRENT_TIME_AEST
        .plus({ hour: spd_data.data?.value[0].GMTDifference })
        .plus({ days: 10 })
        .toFormat("yyyyMMdd'T'HHmmss")
    );
    const canopyFieldsDataQuery = fetchCanopyFieldsData(spd_data.data?.value[0].LocationIDCanopy);
    if (canopyFieldsDataQuery) {
      setCanopyStressLoading(true);
      canopyFieldsDataQuery.then(
        (data: CanopyFieldsDataType) => {
          canopyFieldsData = data;
          getCanopyStresses(
            locationDevicesData.data?.data.value[0].LocationIDCanopy,
            spd_data,
            canopyFieldsData,
            _dateRange,
            numericSystem,
            _startDate,
            interval,
            debug ? 'no-cache' : undefined,
          ).then((data) => {
            setCanopyStress(data);
          }).catch((e) => {
            enqueueSnackbar(
              e.message.split("\n")[0],
              {
                variant: "error",
                persist: true,
              })
          }).finally(() => {
            setCanopyStressLoading(false);
          });
        }
      );
    }
    else {
      setCanopyStressLoading(false);
    }
  });
  const fetchCanopyFieldsData = useCanopyFields();
  const navigate = useNavigate();
  let canopyFieldsData: CanopyFieldsDataType | undefined;
  const [canopyStress, setCanopyStress] = React.useState<ApolloQueryResult<any>>();
  const rootzoneData = useGetRootZoneDailyUsageFromCached(props.locationID);
  const locationDevicesData = useLocationDevicesData(props.locationID, (ldd_data) => {
    if (!ldd_data.data.value[0] || (ldd_data.data.value[0].DeviceCategoryID !== null && ldd_data.data.value[0].DeviceCategoryID !== 15 && ldd_data.data.value[0].DeviceCategoryID !== 3)) {
      // TODO: This is a error message we need to display, but leaving it right now to prioritize other stuff.
      // enqueueSnackbar(t("LocationNotSupportedError"), { variant: "error", persist: true })
      // previous behaviour
      navigate("/pages/404")
    }
    else {
      refetchDataWithDeviceId((
        ldd_data?.data.value[0]?.DeviceID !== undefined
        && ldd_data.data.value[0].DeviceID !== null
        && ldd_data.data.value[0].DeviceID !== 0
      )
        ? ldd_data.data.value[0]?.DeviceID
        : ldd_data.data.value[0]?.DeviceIDLast
      )

      if (ldd_data.data?.value[0].StartDate === null) {
        if (Number(_dateRange) === -1)
          enqueueSnackbar(
            <Trans i18nKey="FieldDetail:StartDateNullError" components={[<a href='mailto:help@goannaag.com.au' />]} />,
            { variant: "error", persist: true }
          )
      }
      else
        LocationCropReportSummaryByLocationIDData.refetch(); // 404 if start date null

      setCanopyStressLoading(true);
      soilProbesData.refetch();
    }
  });

  const satLocationDevicesData = useLocationDevicesData(locationDevicesData.data?.data.value[0].LocationIDSatellite, (sldd_data) => { })
  const LocationCropsByLocationIDData = useLocationCropsByLocationIDData(props.locationID);
  const LocationCropReportSummaryByLocationIDData = useLocationCropReportSummaryByLocationIDData(props.locationID);
  const [BOMForecastData, refetchBomForecastDateRange] = useBOMForecastData(props.locationID);

  const [soilTotalDatawithTemp, refetchSoilTotalDataWithDateRange] = useSoilTotalDatawithTemp(
    props.locationID, "1",
    () => { rootzoneData.refetch() },
    (e: any) => {
      enqueueSnackbar(
        <>{e.message} <Trans i18nKey="FieldDetail:QueryError" components={[<a href='mailto:help@goannaag.com.au' />]} /></>,
        {
          variant: "error",
          persist: true,
        }
      )
    });

  const [rainEventDataSummaryData, refetchRainEventDataSummaryData] = useRainEventDataSummaryData(props.locationID);

  const [deviceData, refetchDataWithDeviceId] = useDevicesData();

  const CANOPY_STRESS_FORECAST = gql`
  query ($unit: UnitEnum!, $location_id: ID!) {
        weather_forecast(where: { location_id: $location_id }) {
          data {
            date_time
            solar_rad_forecast(digits: 2)
            rel_humid_forecast(digits: 2)
            air_temp_forecast(digits: 2, unit: $unit)
            windSpeed_forecast(digits: 2)
            canopy_temp_forecast(digits: 2, unit: $unit)
            deficit_hours_forecast
            acc_stress_time_above_threshold_forecast(digits: 2)
            stress_time_forecast(digits: 2)
            full_point
          }
        }
      }
      `;

  const [getCanopyStressForecast, canopyStressForecast] = useLazyQuery(CANOPY_STRESS_FORECAST, {
    variables: {
      'unit': numericSystem === 'M' ? unit[0] : unit[1],
      'location_id': locationDevicesData.data?.data.value[0].LocationIDCanopy
    },
    client
  });

  const USER_ACCOUNTS_QUERY = gql`
  query {
    user{
      accounts {
        id
      }
    }
  }`

  const [getUserAccounts, userAccounts] = useLazyQuery(USER_ACCOUNTS_QUERY, {
   client: secondaryClient
  });

  React.useEffect(() => {
    getUserAccounts();
  }, [])

  //Issue 860 we are changing the query date from the start date to the planting date, due to the fact that this GET request will trigger a stored proc that calculates from the start date
  const satelliteFieldDataSummary = useGetSatelliteFieldDataSummaryForDateRange(
    locationDevicesData.data?.data.value[0].LocationIDSatellite,
    DateTime.fromISO(locationDevicesData.data?.data.value[0].PlantingDate).toFormat("yyyyMMdd'T000000'"),
    (dateRange[1] as DateTime).toFormat("yyyyMMdd'T235959'")
  );

  const [canopyStressLoading, setCanopyStressLoading] = React.useState(false);

  const refetchCanopyStress = async () => {
    setCanopyStressLoading(true);
    const data = await getCanopyStresses(
      locationDevicesData.data?.data.value[0].LocationIDCanopy,
      soilProbesData.data,
      canopyFieldsData,
      dateRange,
      numericSystem,
      startDate,
      interval,
      debug ? 'no-cache' : undefined
    ).finally(() => {
      setCanopyStressLoading(false);
    });
    setCanopyStress(data);
  };

  function setStartDateDateRange(
    spd_data: soilProbesDataType,
    ldd_data: locationDevicesDataType
  ): [DateTime, DateRange<DateTime>] {
    if (ldd_data.data?.value[0].StartDate === null) {
      const _startDate = DateTime.local({ zone: AEST_Zone }).minus({ days: 7 }).startOf("day")
      setStartDate(_startDate)
      setDateRangeReady(true)
      return [_startDate, dateRange]
    }
    else {
      if (
        spd_data.data?.value[0].GMTDifference !== undefined
        && spd_data.data?.value[0].GMTDifference !== null
        && ldd_data.data?.value[0].StartDate
        && !dateRangeReady
      ) {
        const _startDate = DateTime.fromISO(
          aestToLocationLocalTimeConverter(
            ldd_data.data.value[0].StartDate,
            spd_data.data.value[0].GMTDifference
          )
        )
        if (Number(_dateRange) === -1 || !dateRange[0] || _startDate.toMillis() > dateRange[0].toMillis()) {
          const diff = NOW.diff(_startDate, "days").days
          let newDateRange: DateRange<DateTime> = [_startDate, dateRange[1]]
          if (diff > 180) {
            newDateRange = [_startDate, getDaysAfter(_startDate, 180)];
            enqueueSnackbar({
              variant: 'warning',
              message: `180 days limit exceeded, changing the date range to ${newDateRange[0]?.toFormat(numericSystem === 'M' ? 'dd/MM/yyyy' : 'MM/dd/yyyy') + ' - ' + newDateRange[1]?.toFormat(numericSystem === 'M' ? 'dd/MM/yyyy' : 'MM/dd/yyyy')}`,
              persist: false
            })
          }
          setStartDate(_startDate);
          setDateRange(newDateRange);
          setDateRangeReady(true);
          return [_startDate, newDateRange];
        }
        else if (_startDate.toMillis() <= dateRange[0].toMillis()) {
          setStartDate(_startDate);
          setDateRangeReady(true);
          return [_startDate, dateRange];
        }
        else {
          setDateRangeReady(true);
          return [startDate ?? undefined, dateRange];
        }
      }
      else { return [startDate ?? undefined, dateRange] }
    }
  }

  React.useEffect(() => {
    LocationsEdit.refetch();
    locationDevicesData.refetch();
    LocationCropsByLocationIDData.refetch();
  }, []);

  React.useEffect(() => {
    if (dateRangeReady) {
      refetchSoilTotalDataWithDateRange(
        (dateRange[0] as DateTime).toFormat("yyyyMMdd'T000000'"),
        (dateRange[1] as DateTime).toFormat("yyyyMMdd'T235959'")
      );
      locationDevicesData.refetch();
      // refetchRainEventDataSummaryData(
      //   (dateRange[0] as DateTime).toFormat("yyyyMMdd'T000000'"),
      //   CURRENT_TIME_AEST
      //     .plus({ hour: soilProbesData.data?.data.value[0].GMTDifference })
      //     .plus({ days: 10 })
      //     .toFormat("yyyyMMdd'T'HHmmss")
      // );
    }
  }, [dateRange, dateRangeReady]);

  //fetch canopy stress data when location has canopy
  React.useEffect(() => {
    if (locationDevicesData.data?.data.value[0].LocationIDCanopy
      && dateRangeReady
      && (dateRange[1] as DateTime).toISODate() >= NOW.toISODate()
    ) {
      getCanopyStressForecast();
      refetchCanopyStress();
    }
  }, [dateRange, dateRangeReady, locationDevicesData.data?.data.value[0].LocationIDCanopy]);

  //fetch satillite field summary when location has satellite
  React.useEffect(() => {
    if (locationDevicesData.data?.data.value[0].LocationIDSatellite && locationDevicesData.data?.data.value[0].PlantingDate && dateRangeReady) {
      satelliteFieldDataSummary.refetch();
      if (debug) satLocationDevicesData.refetch();
    }
  }, [dateRange, dateRangeReady, locationDevicesData.data?.data.value[0].LocationIDSatellite, locationDevicesData.data?.data.value[0].PlantingDate]);

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    // Set loading to false once all data are fetched
    const loadingStatus = !soilProbesData.isLoading && !soilProbesData.isError &&
      !locationDevicesData.isLoading && !locationDevicesData.isError &&
      !LocationCropsByLocationIDData.isLoading && !LocationCropsByLocationIDData.isError &&
      !LocationCropReportSummaryByLocationIDData.isLoading && !LocationCropReportSummaryByLocationIDData.isError &&
      !BOMForecastData.isLoading && !BOMForecastData.isError &&
      !soilTotalDatawithTemp.isLoading && !soilTotalDatawithTemp.isError &&
      !rainEventDataSummaryData.isLoading && !rainEventDataSummaryData.isError &&
      !deviceData.isLoading && !deviceData.isError;

    if (locationDevicesData.data?.data.value[0].LocationIDCanopy) {
      if (loadingStatus &&
        !satelliteFieldDataSummary.isLoading && !satelliteFieldDataSummary.isError &&
        !canopyStressLoading
      ) {
        setLoading(false);
      }
      else {
        setLoading(true);
      }
    }
    else {
      if (loadingStatus &&
        !satelliteFieldDataSummary.isLoading && !satelliteFieldDataSummary.isError
      ) {
        setLoading(false);
      }
      else {
        setLoading(true);
      }
    }

  }, [
    soilProbesData.isLoading,
    locationDevicesData.isLoading,
    LocationCropsByLocationIDData.isLoading,
    LocationCropReportSummaryByLocationIDData.isLoading,
    BOMForecastData.isLoading,
    soilTotalDatawithTemp.isLoading,
    rainEventDataSummaryData.isLoading,
    deviceData.isLoading,
    satelliteFieldDataSummary.isLoading,
    canopyStressLoading,
    // locationDevicesData.data?.data.value[0].LocationIDSatellite,
    locationDevicesData.data?.data.value[0].LocationIDCanopy,
  ]);


  return (
    <FieldDetailContext.Provider value={{
      locationID: props.locationID,
      dateRange: dateRange,
      setDateRange: setDateRange,
      soilProbesData,
      BOMForecastData,
      soilTotalDatawithTemp,
      rainEventDataSummaryData,
      canopyStressForecast,
      canopyFieldsData,
      canopyStress,
      locationDevicesData,
      satLocationDevicesData,
      LocationCropsByLocationIDData,
      LocationsEdit,
      LocationCropReportSummaryByLocationIDData,
      rootzoneData,
      deviceData,
      satelliteFieldDataSummary,
      startDate,
      refetchCanopyStress,
      canopyStressLoading,
      loading,
      dateRangeReady,
      interval,
      setInterval,
      userAccounts,
    }}>
      {props.children}
    </FieldDetailContext.Provider>
  )
}

export { FieldDetailContext, FieldDetailProvider };